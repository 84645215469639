import { User } from '@/models/User';
import { Subject } from "rxjs";
import Vue from 'vue';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
import Router from './Router';
import BaseService from '@/services/BaseService';
import router from '@/router';
import Config from '@/Config';
export default class Sentinel {
    static setUser(user, updateTS = true) {
        this.user = user instanceof User ? user : new User(user);
        if (updateTS) {
            Vue.$cookies.set(Config.user_cookie, Object.assign({}, this.user), new Date(user.ts_expiration.toString()));
        }
        else {
            var cookiesUser = Vue.$cookies.get(Config.user_cookie);
            this.user.token = cookiesUser.token;
            this.user.ts_expiration = cookiesUser.ts_expiration;
            var actualTS = this.user.ts_expiration.toString();
            Vue.$cookies.set(Config.user_cookie, Object.assign({}, this.user), new Date(actualTS));
        }
        this.userSubject.next(this.user);
    }
    static getUser(checkUser = false, redirect) {
        if (checkUser) {
            if (this.publicPages.filter(url => router.currentRoute.path.includes(url)).length == 0) {
                Sentinel.checkUser(redirect);
            }
        }
        return this.user;
    }
    static watchUser(subscriber, observer) {
        const subscription = this.userSubject.asObservable().subscribe(observer);
        if (subscriber && subscriber instanceof Vue) {
            subscriber.$once('hook:beforeDestroy', () => {
                subscription.unsubscribe();
            });
        }
    }
    static removeUser(redirect) {
        Vue.$cookies.remove(Config.user_cookie);
        this.user = new User();
        this.userSubject.next(this.user);
        if (redirect) {
            Router.goTo(redirect);
        }
    }
    static checkUser(redirectError, redirectOK) {
        if (Vue.$cookies.isKey(Config.user_cookie) && Vue.$cookies.get(Config.user_cookie).token) {
            BaseService.ping().subscribe((user) => {
                if (user.id) {
                    Sentinel.setUser(user, false);
                    if (redirectOK) {
                        Router.goTo(redirectOK);
                    }
                }
                else {
                    Sentinel.removeUser(redirectError);
                }
            }, error => Sentinel.removeUser(redirectError));
        }
        else {
            Sentinel.removeUser(redirectError);
        }
    }
}
Sentinel.user = new User();
Sentinel.userSubject = new Subject();
Sentinel.publicPages = new Array('confirmacion-registro/');
Sentinel.privatePages = new Array();
