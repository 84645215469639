import * as tslib_1 from "tslib";
import JSPage from "@/classes/JSPage";
import EventService from "@/services/EventService";
import moment from "moment";
import Component from "vue-class-component";
import Event from '@/models/Event';
import MaintenanceActionService from "@/services/MaintenanceActionService";
import { Prop, Watch } from "vue-property-decorator";
import MaintenanceService from "@/services/MaintenanceService";
import DatePicker from "@/components/Base/DatePicker/DatePicker.vue";
export class Sheet {
    constructor(localization, day, event, action) {
        this.localization = localization;
        this.day = day;
        this.event = event;
        this.action = action;
    }
}
let DashboardPage = class DashboardPage extends JSPage {
    constructor() {
        super(...arguments);
        this.maintenanceService = new MaintenanceService();
        this.maintenance = {};
        this.eventService = new EventService();
        this.events = [];
        this.overlapped = [];
        this.actionService = new MaintenanceActionService();
        this.actionEnums = [];
        this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        this.start = moment().set('year', this.year).set('month', this.month - 1).startOf('month').toDate();
        this.end = moment(this.start).add(4, 'days').toDate();
    }
    onMonthOrYearChanged() {
        this.start = moment().set('year', this.year).set('month', this.month - 1).startOf('month').toDate();
        this.end = moment(this.start).add(4, 'days').toDate();
        this.loadEvents();
    }
    // @Watch( 'start' )
    // @Watch( 'end' )
    // onPeriodChanged() {
    //     this.loadEvents();
    // }
    onValueChanged() {
        if (this.value) {
            this.maintenance = this.value;
            if (this.maintenance.actions) {
                this.maintenance.actions.forEach((action) => {
                    action.id_event = action.id_event.value;
                    action.type = action.type.value;
                });
            }
            if (this.value.days.length > 0) {
                this.start = moment().set('year', this.value.year).set('month', this.value.month - 1).set('date', this.value.days[0]).startOf('day').toDate();
                this.end = moment().set('year', this.value.year).set('month', this.value.month - 1).set('date', this.value.days[this.value.days.length - 1]).startOf('day').toDate();
            }
            this.loadEvents();
        }
    }
    created() {
        super.created();
        this.getEnums();
        this.onValueChanged();
    }
    startOfMonth() {
        return moment().set('year', this.year).set('month', this.month - 1).startOf('month').format();
    }
    endOfMonth() {
        return moment().set('year', this.year).set('month', this.month - 1).endOf('month').format();
    }
    getEnums() {
        this.actionService.getEnums().subscribe((enums) => {
            this.actionEnums = enums['Type'];
            this.actionEnums.unshift({ value: -1, strValue: '' });
        });
    }
    getOverlapped() {
        this.overlapped = [];
        this.eventService.getOverlapped(moment(this.start).format('YYYY-MM-DD'), moment(this.end).format('YYYY-MM-DD')).subscribe((overlapped) => {
            overlapped.forEach((overlap) => {
                for (let date = moment(this.start); date <= moment(this.end); date.add(1, 'day')) {
                    const dateStr = date.format('YYYY-MM-DD');
                    const event = overlap.dates[dateStr];
                    overlap.dates[dateStr] = {};
                    if (event) {
                        const ev = this.events.find((ev) => ev.id == event);
                        if (ev) {
                            overlap.dates[dateStr].event = new Event(ev);
                        }
                        else {
                            overlap.dates[dateStr].event = new Event({ id: event });
                        }
                        overlap.dates[dateStr].action = this.value.actions.find((action) => action.date == dateStr && action.id_event === overlap.dates[dateStr].event.id) ||
                            {
                                id_event: overlap.dates[dateStr].event.id,
                                day: date.get('date'),
                                type: -1,
                                note: '',
                                date: dateStr
                            };
                    }
                    else {
                        overlap.dates[dateStr].event = undefined;
                        overlap.dates[dateStr].action = undefined;
                    }
                }
            });
            this.overlapped = overlapped;
        });
    }
    loadEvents() {
        console.log('loading');
        const start = moment(this.start).format('YYYY-MM-DD');
        const end = moment(this.end).format('YYYY-MM-DD');
        this.events = [];
        this.eventService.getAll('start_ts=ge=' + start + ';start_ts=le=' + end + ';show_in_maintenance==1', undefined, 1, 999999).subscribe((data) => {
            data.data.forEach((item) => {
                this.events.push(new Event(item));
            });
            this.getOverlapped();
        });
    }
    getDays() {
        let days = [];
        for (let day = moment(this.start); day <= moment(this.end); day.add(1, 'day')) {
            days.push(day.get('date'));
        }
        return days;
    }
    getDaysStr() {
        let days = [];
        for (let day = moment(this.start); day <= moment(this.end); day.add(1, 'day')) {
            let dayName = day.locale('es').format('dddd');
            dayName = dayName.substr(0, 1).toUpperCase() + dayName.substr(1);
            days.push(dayName + ' día ' + day.get('date'));
        }
        return days;
    }
    getDates() {
        let dates = [];
        for (let date = moment(this.start); date <= moment(this.end); date.add(1, 'day')) {
            dates.push(date.format('YYYY-MM-DD'));
        }
        return dates;
    }
    getColumnsWidth() {
        return 80 / this.getDays().length;
    }
    save() {
        if (this.isValid()) {
            this.maintenance.actions = [];
            this.overlapped.forEach((overlap) => {
                Array.from(Object.values(overlap.dates)).forEach((date) => {
                    if (date.action && date.action.type !== -1) {
                        this.maintenance.actions.push(date.action);
                    }
                });
            });
            if (this.maintenance.actions && this.maintenance.actions.length > 0) {
                this.maintenance.days = this.getDays();
                if (!this.maintenance.id) {
                    this.maintenanceService.add(this.maintenance).subscribe((maintenance) => this.$emit('input', maintenance));
                }
                else {
                    this.maintenanceService.update(this.maintenance).subscribe((maintenance) => {
                        this.$emit('input', maintenance);
                    });
                }
            }
        }
    }
    isValid() {
        // if( !this.mnt.days.find( ( day : any ) => day !== undefined ) ) {
        //     CBlocker.showError( 'Debe añadir al menos un día a la hoja de mantenimiento' );
        //     return false;
        // }
        return true;
    }
};
tslib_1.__decorate([
    Prop({ default: moment().month() + 1 })
], DashboardPage.prototype, "month", void 0);
tslib_1.__decorate([
    Prop({ default: moment().year() })
], DashboardPage.prototype, "year", void 0);
tslib_1.__decorate([
    Prop()
], DashboardPage.prototype, "value", void 0);
tslib_1.__decorate([
    Watch('month'),
    Watch('year')
], DashboardPage.prototype, "onMonthOrYearChanged", null);
tslib_1.__decorate([
    Watch('value')
], DashboardPage.prototype, "onValueChanged", null);
DashboardPage = tslib_1.__decorate([
    Component({
        components: {
            DatePicker
        }
    })
], DashboardPage);
export default DashboardPage;
