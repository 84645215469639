import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import InputField from '@/components/InputField/InputField.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import JSPage from '@/classes/JSPage';
import EventTypeService from '@/services/EventTypeService';
import MaterialUploader from '@/components/Base/MaterialUploader/MaterialUploader.vue';
import EventType from '@/models/EventType';
let EventTypePage = class EventTypePage extends JSPage {
    constructor() {
        super(...arguments);
        this.eventTypeService = new EventTypeService();
        this.EventType = EventType;
        this.enumType = {};
        this.headers = [];
        this.filters = [];
        this.deletionMessage = '¿Está seguro de que desea eliminar este elemento? Si lo elimina, todos los eventos asociados serán también eliminados.';
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Icono', value: 'icon', align: 'start', sortable: false, type: IDataTableHeaderType.IMAGE, maxWidth: 64 },
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Descripción', value: 'description', align: 'start', sortable: false },
            { text: 'Tipo', value: 'type.strValue', align: 'start', sortable: false },
            { text: '¿Mantenimiento?', value: 'showInMaintenance', align: 'center', sortable: false },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' }
        ];
    }
    getEnums() {
        this.eventTypeService.getEnums().subscribe((enums) => {
            this.enumType = enums['Type'];
            // if ( !this.eventType.id ) {
            //     this.user_.type = this.enumType.filter( elem => elem.value === parseInt( this.$attrs.type ) )[0];                    
            // }                
        });
    }
    onNewItem(item) {
        item.type = this.enumType[0];
    }
};
EventTypePage = tslib_1.__decorate([
    Component({
        components: {
            DataTable, InputField, MaterialUploader
        }
    })
], EventTypePage);
export default EventTypePage;
