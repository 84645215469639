import axios from 'axios';
import { Observable } from "rxjs";
import router from '@/router';
import Vue from 'vue';
import { CBlocker } from '@/components/Blocker/CBlocker';
import Config from '@/Config';
export default class BaseService {
    static getToken() {
        if (Vue.$cookies.isKey(Config.user_cookie) && Vue.$cookies.get(Config.user_cookie).token) {
            return Vue.$cookies.get(Config.user_cookie).token;
        }
        return '';
    }
    static ping() {
        return BaseService.get('ping');
    }
    static get(path, content, userAuthentication = true, showBlocker = true) {
        if (showBlocker) {
            CBlocker.showLoading();
        }
        let headers = {
            'Content-Type': 'application/json',
            'x-api-key': BaseService.API_KEY,
            'Authorization': ''
        };
        if (userAuthentication) {
            if (this.getToken() == '') {
                if (router.currentRoute.path !== '/') {
                    router.replace('/');
                }
            }
            headers.Authorization = this.getToken();
        }
        let payload = BaseService.normalizeContent(content, headers);
        return new Observable((observer) => {
            axios.get(this.baseURL + path, {
                headers: headers,
                params: payload
            }).then((response) => {
                if (response.data.error == 0) {
                    observer.next(response.data.data);
                    observer.complete();
                }
                else {
                    if (response.data.error <= 6) {
                        router.replace('/');
                    }
                    observer.error(response.data);
                }
            }).catch((error) => {
                observer.error(error);
            }).finally(() => {
                if (showBlocker) {
                    CBlocker.hide();
                }
            });
        });
    }
    static post(path, content, userAuthentication = true, showBlocker = true) {
        if (showBlocker) {
            CBlocker.showLoading();
        }
        let headers = {
            'Content-Type': 'application/json',
            'x-api-key': BaseService.API_KEY,
            'Authorization': ''
        };
        if (userAuthentication) {
            if (this.getToken() == '') {
                if (router.currentRoute.path !== '/') {
                    router.replace('/');
                }
            }
            headers.Authorization = this.getToken();
        }
        let payload = BaseService.normalizeContent(content, headers);
        return new Observable((observer) => {
            axios.post(this.baseURL + path, payload, {
                headers: headers
            }).then((response) => {
                if (response.data.error == 0) {
                    observer.next(response.data.data);
                    observer.complete();
                }
                else {
                    if (response.data.error <= 6) {
                        router.replace('/');
                    }
                    observer.error(response.data);
                }
            }).catch((error) => {
                observer.error(error);
            }).finally(() => {
                if (showBlocker) {
                    CBlocker.hide();
                }
            });
        });
    }
    static put(path, content, userAuthentication = true, showBlocker = true) {
        if (showBlocker) {
            CBlocker.showLoading();
        }
        let headers = {
            'Content-Type': 'application/json',
            'x-api-key': BaseService.API_KEY,
            'Authorization': ''
        };
        if (userAuthentication) {
            if (this.getToken() == '') {
                if (router.currentRoute.path !== '/') {
                    router.replace('/');
                }
            }
            headers.Authorization = this.getToken();
        }
        let payload = BaseService.normalizeContent(content, headers);
        if (BaseService.isMultipartFormData(content)) {
            payload.set('_method', 'PUT');
        }
        else {
            payload['_method'] = 'PUT';
        }
        return new Observable((observer) => {
            axios.post(this.baseURL + path, payload, {
                headers: headers
            }).then((response) => {
                if (response.data.error == 0) {
                    observer.next(response.data.data);
                    observer.complete();
                }
                else {
                    if (response.data.error <= 6) {
                        router.replace('/');
                    }
                    observer.error(response.data);
                }
            }).catch((error) => {
                observer.error(error);
            }).finally(() => {
                if (showBlocker) {
                    CBlocker.hide();
                }
            });
        });
    }
    static delete(path, userAuthentication = true, showBlocker = true) {
        if (showBlocker) {
            CBlocker.showLoading();
        }
        let headers = {
            'Content-Type': 'application/json',
            'x-api-key': BaseService.API_KEY,
            'Authorization': ''
        };
        if (userAuthentication) {
            if (this.getToken() == '') {
                if (router.currentRoute.path !== '/') {
                    router.replace('/');
                }
            }
            headers.Authorization = this.getToken();
        }
        return new Observable((observer) => {
            axios.delete(this.baseURL + path, {
                headers: headers
            }).then((response) => {
                if (response.data.error == 0) {
                    observer.next(response.data.data);
                    observer.complete();
                }
                else {
                    if (response.data.error <= 6) {
                        router.replace('/');
                    }
                    observer.error(response.data);
                }
            }).catch((error) => {
                observer.error(error);
            }).finally(() => {
                if (showBlocker) {
                    CBlocker.hide();
                }
            });
        });
    }
    static normalizeContent(content, headers) {
        if (BaseService.isMultipartFormData(content)) {
            headers['Content-Type'] = 'multipart/formdata';
            return BaseService.formDataPayload(content);
        }
        else {
            return BaseService.jsonPayload(content);
        }
    }
    static jsonPayload(content) {
        let payload = {};
        if (content) {
            for (let [key, value] of Object.entries(content)) {
                if (content[key] instanceof Object && content[key].value !== undefined && content[key].strValue !== undefined) {
                    if (content[key].value != -1) {
                        payload[key] = content[key].value;
                    }
                }
                else if (key != 'id' && !(content[key] instanceof File)) {
                    payload[key] = content[key];
                }
            }
        }
        return payload;
    }
    static formDataPayload(content) {
        let payload = new FormData();
        if (content) {
            payload.set('json', JSON.stringify(BaseService.jsonPayload(content)));
            for (let [key, value] of Object.entries(content)) {
                if (content[key] instanceof File) {
                    var _key = key.replace('_FILE', '');
                    payload.append(_key, content[key]);
                }
            }
        }
        return payload;
    }
    static isMultipartFormData(content) {
        if (content) {
            for (let [key, value] of Object.entries(content)) {
                if (content[key] instanceof File) {
                    return true;
                }
            }
        }
        return false;
    }
}
BaseService.baseURL = 'https://elecfes.juice-studio.com/api/';
BaseService.API_KEY = '4327d173af0eba0e6e31fe6a3282a5d4';
