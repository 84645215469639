import BaseService from './BaseService';
import { Observable } from 'rxjs';
import { CBlocker } from '@/components/Blocker/CBlocker';
export default class ObservationService {
    constructor() {
        this.path = 'observations';
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(date) {
        return new Observable((observer) => {
            this.getAll("date==" + date.toString(), undefined, 1, 1).subscribe((observations) => {
                observer.next(observations.data.length > 0 ? observations.data[0] : {});
            }, (error) => observer.error(error));
        });
    }
    add(reg) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.post(this.path, reg, true, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Guardado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    update(observation) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.put(this.path + '/' + observation.id, observation, true, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Guardado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
}
