import * as tslib_1 from "tslib";
import JSPage from "@/classes/JSPage";
import Router from "@/classes/Router";
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import moment from "moment";
import Component from "vue-class-component";
import MaintenanceService from "@/services/MaintenanceService";
import draggable from "vuedraggable";
import MaintenanceDetails from "../MaintenanceDetails/MaintenanceDetails.vue";
import { CBlocker } from "@/components/Blocker/CBlocker";
import { Watch } from "vue-property-decorator";
let DashboardPage = class DashboardPage extends JSPage {
    constructor() {
        super(...arguments);
        this.maintenanceService = new MaintenanceService();
        this.maintenances = [];
        this.tab = '';
        this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        this.month = moment().month() + 1;
        this.year = moment().year();
    }
    onRouteChanged(newValue) {
        this.year = parseInt(newValue.query['y']);
        this.year = isNaN(this.year) ? moment().year() : this.year;
        this.month = parseInt(newValue.query['m']);
        this.month = isNaN(this.month) ? moment().month() + 1 : this.month;
        this.onMonthOrYearChanged();
    }
    onMonthOrYearChanged() {
        this.loadMaintenances();
    }
    loadMaintenances() {
        this.maintenanceService.getAll('month==' + this.month.toString() + ';year==' + this.year.toString(), 'order=ASC', 1, 9999).subscribe((data) => {
            this.maintenances = data.data;
            this.maintenances.forEach((maintenance) => {
                maintenance.days = maintenance.days.split(',');
            });
            if (this.maintenances.length === 0) {
                this.addNewMaintenance();
            }
        });
    }
    addNewMaintenance() {
        this.maintenances.push({
            month: this.month,
            year: this.year,
            days: [],
            title: '',
            staff: '',
            vehicle: '',
            phone: '',
            observations: '',
            order: 255,
            actions: []
        });
    }
    created() {
        super.created();
        this.onRouteChanged(this.$route);
    }
    onBackButtonPressed() {
        Router.goBack();
    }
    onDeleteClicked(maintenance) {
        CBlocker.showConfirm('¿Está seguro de que desea eliminar esta hoja? Esta operación es irreversible.', () => {
            this.maintenanceService.delete(maintenance).subscribe((item) => {
                this.loadMaintenances();
            }, (error) => {
            });
        });
    }
    onDragDropTab() {
        this.maintenanceService.order(this.maintenances.map((maintenance) => maintenance.id)).subscribe();
    }
    onPrintClicked(maintenance) {
        const printer = window.open('', '', 'left=0,top=0,width=1000,height=800,toolbar=0,scrollbars=0,status=0');
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }
        const mnt = document.querySelector('#maintenance_detail_' + maintenance.id).cloneNode(true);
        const dates = mnt.querySelectorAll('.datepicker input');
        const table = mnt.querySelector('.actions-table');
        table.querySelectorAll('.v-input').forEach((selector) => {
            const value = selector.querySelector('.v-select__selection').innerHTML;
            const classes = selector.className;
            selector.outerHTML = `<div class="eventType ${classes}">` + value + '</div>';
        });
        table.querySelectorAll('textarea').forEach((selector) => {
            const value = selector.value;
            const classes = selector.className;
            selector.outerHTML = `<div class="textarea ${classes}">` + value + '</div>';
        });
        printer.document.write(`<!DOCTYPE html>
            <html>
                <head>
                    ${stylesHtml}
                </head>
                <body class='printer maintenance'>
                    <button class='print-button' onClick='window.print();return false;'>
                        Imprimir
                    </button>
                    <div class='maintenance_title'>
                        ${maintenance.title || '&nbsp;'}
                    </div>
                    <div class='maintenance_period'>
                        <strong>Periodo:</strong> 
                        <div class='maintenance_ranges'>
                            <div class='maintenance_range'>
                                <div class='maintenance_date_title'>
                                    Fecha de inicio
                                </div>
                                <div class='maintenance_date'>
                                    <i class='mdi mdi-calendar'></i>${dates[0].value} 
                                </div>
                            </div>
                            <div class='maintenance_range'>
                                <div class='maintenance_date_title'>
                                    Fecha de fin
                                </div>
                                <div class='maintenance_date'>
                                    <i class='mdi mdi-calendar'></i>${dates[1].value}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='maintenance_staff_car_phone'>
                        <div class='maintenance_staff'>
                            <strong>Personal: </strong>
                            <div class='maintenance_staff_name'>
                                ${maintenance.staff || '&nbsp;'}        
                            </div>
                        </div>
                        <div class='maintenance_car'>
                            <strong>Vehículo: </strong>
                            <div class='maintenance_car_name'>                            
                                ${maintenance.vehicle || '&nbsp;'}        
                            </div>
                        </div>
                        <div class='maintenance_phone'>
                            <strong>Teléfono: </strong>
                            <div class='maintenance_phone_name'>
                                ${maintenance.phone || '&nbsp;'}        
                            </div>
                        </div>                                                
                    </div>
                    <table class='maintenance-detail'>
                            ${table.innerHTML.replaceAll('·', '')}
                    </table>
                    <div class='maintenance_observations'>
                        <strong>Observaciones: </strong>
                        <div class='maintenance_observations_content'>
                            ${maintenance.observations && maintenance.observations !== null ? maintenance.observations : ""}
                        </div>
                    </div>
                </body>
            </html>`);
        printer.document.close();
        // printer!.print();
    }
};
tslib_1.__decorate([
    Watch('$route')
], DashboardPage.prototype, "onRouteChanged", null);
DashboardPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, draggable, MaintenanceDetails
        }
    })
], DashboardPage);
export default DashboardPage;
