import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import JSPage from '@/classes/JSPage';
import FullCalendar from '@/components/FullCalendar/FullCalendar.vue';
import Router from '@/classes/Router';
import Calendar from '@/components/Calendar/Calendar.vue';
import moment from 'moment';
import MaterialCard from '@/components/Base/MaterialCard/MaterialCard.vue';
import EventService from '@/services/EventService';
import Event from '@/models/Event';
import InputField from '@/components/InputField/InputField.vue';
let SearchDetailsPage = class SearchDetailsPage extends JSPage {
    constructor() {
        super(...arguments);
        this.eventService = new EventService();
        this.events = new Array();
        this.year = moment().year();
        this.search = '';
    }
    onRouteChanged(newValue) {
        this.year = parseInt(newValue.query['y']);
        this.year = isNaN(this.year) ? moment().year() : this.year;
        this.getEvents();
    }
    created() {
        this.onRouteChanged(this.$route);
    }
    onBackButtonPressed() {
        Router.removeQueryFromURL('s');
        Router.removeQueryFromURL('sy');
        this.$emit('input', false);
    }
    getEvents() {
        if (this.$route.query['s']) {
            this.search = this.$route.query['s'].toString();
        }
        if (this.$route.query['sy']) {
            this.year = parseInt(this.$route.query['sy'].toString());
        }
        if (this.search == '') {
            this.$emit('input', false);
            return;
        }
        const start_date = moment(this.year + '-01-01', 'YYYY-M-D').locale('es');
        const end_date = moment(this.year + '-12-31', 'YYYY-M-D').locale('es');
        this.eventService.getAll('start_ts=like=' + this.year + "-%;localization=like=%" + this.search + "%", 'start_ts=ASC', 1, 999999).subscribe((data) => {
            this.events = new Array();
            var auxEvents = new Map();
            data.data.forEach((item) => {
                let event = new Event(item);
                if (item.event_type_start_type.value == 1 || item.event_type_start_type.value == 2) {
                    let localizationEvents = auxEvents.get(event.localization);
                    if (!localizationEvents) {
                        localizationEvents = new Array();
                    }
                    localizationEvents.push(event);
                    auxEvents.set(event.localization, localizationEvents);
                }
            });
            Array.from(auxEvents).forEach(([key, value]) => {
                let previous = null;
                value.forEach((event) => {
                    let isOn = event.event_type_start_type.value == 1;
                    let isOff = event.event_type_start_type.value == 2;
                    if (isOn) {
                        this.events.push({
                            id: event.id,
                            localization: event.localization,
                            start_date: event.startDateStr || '---',
                            end_date: '---',
                        });
                        previous = event;
                    }
                    else {
                        if (previous && previous.event_type_start_type.value == 1) {
                            this.events[this.events.length - 1].end_date = event.startDateStr;
                            previous = event;
                        }
                        else {
                            this.events.push({
                                id: event.id,
                                localization: event.localization,
                                start_date: '---',
                                end_date: event.startDateStr,
                            });
                            previous = null;
                        }
                    }
                });
                // let eventIndex = this.events.map( ( event : ISearchEvent ) => event.localization ).lastIndexOf( item.localization );                        
                // if( eventIndex != -1 ) {
                //     this.events[ eventIndex ].end_date = event.startDateStr;
                // }
                // else if( item.event_type_start_type.value == 1 ) {
                //     this.events.push({ id : event.id, localization : event.localization, start_date : event.startDateStr, end_date : event.endDateStr ? event.endDateStr : '---' } as ISearchEvent);
                // }
                // else {
                //     this.events.push({ id : event.id, localization : event.localization, start_date : '---', end_date : event.endDateStr ? event.endDateStr : '---' } as ISearchEvent);
                // }
                // }
            });
        });
    }
    scrollToEnd() {
        let doc = document;
        doc.querySelector('tbody').scrollTop = doc.querySelector('tbody').scrollHeight;
    }
};
tslib_1.__decorate([
    Prop({ default: false })
], SearchDetailsPage.prototype, "value", void 0);
tslib_1.__decorate([
    Watch('$route')
], SearchDetailsPage.prototype, "onRouteChanged", null);
SearchDetailsPage = tslib_1.__decorate([
    Component({
        components: {
            FullCalendar, Calendar, MaterialCard, InputField
        }
    })
], SearchDetailsPage);
export default SearchDetailsPage;
