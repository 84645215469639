import { EnumEntry } from './EnumEntry';
import Assets from "@/classes/Assets";
export class User {
    constructor(data) {
        this.name = '';
        this.email = '';
        this.type = new EnumEntry();
        this.status = new EnumEntry();
        this.image = '';
        this._notFoundImage = false;
        this._DEFAULT_IMAGE_ADMIN = Assets.getImage('default_admin.png');
        this._DEFAULT_IMAGE_USER = Assets.getImage('default_user.png');
        this.id = 0;
        if (data) {
            Object.assign(this, data);
            this.ts_expiration = data.ts_expiration ? data.ts_expiration : new Date();
        }
    }
    getImageSrc() {
        return (this.image && this.image !== '' && !this._notFoundImage) ?
            this.image :
            this.getDefaultImage();
    }
    getDefaultImage() {
        return this.isAdmin() || this.isUser() ?
            this._DEFAULT_IMAGE_ADMIN :
            this._DEFAULT_IMAGE_USER;
    }
    catchImageError() {
        this._notFoundImage = true;
    }
    getTypeStr() {
        switch (this.type.value) {
            case User.TYPE_ADMINISTRATOR:
                return 'Administrador';
            case User.TYPE_USER:
                return 'Técnico';
            default:
                return '';
        }
    }
    getStatusStr() {
        return !this.status ? '' : this.status.strValue;
    }
    toNormalize() {
        let user = Object.assign({}, this);
        if (user.type instanceof Object) {
            user.type = user.type.value;
        }
        if (user.status instanceof Object) {
            user.status = user.status.value;
        }
        delete (user.id);
        delete (user.token);
        delete (user.ts_expiration);
        delete (user.last_access);
        delete (user._notFoundImage);
        delete (user._DEFAULT_IMAGE_ADMIN);
        delete (user._DEFAULT_IMAGE_USER);
        if (user.image_FILE) {
            user.image = user.image_FILE;
        }
        delete (user.image_FILE);
        return user;
    }
    isAdmin() {
        return this.type && this.type.value === User.TYPE_ADMINISTRATOR;
    }
    isUser() {
        return this.type && this.type.value === User.TYPE_USER;
    }
    isLogged() {
        return this.token && this.token !== '';
    }
}
User.TYPE_ADMINISTRATOR = 99;
User.TYPE_USER = 100;
User.STATUS_ACTIVE = 1;
User.STATUS_INACTIVE = 2;
