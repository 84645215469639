import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import { User } from '@/models/User';
import { mapMutations, mapState } from 'vuex';
import MenuItem from "@/components/Base/MenuItem/MenuItem.vue";
import MenuItemGroup from '@/components/Base/MenuItem/MenuItemGroup/MenuItemGroup.vue';
import Globals from '@/classes/Globals';
import JSPage from '@/classes/JSPage';
import Assets from '@/classes/Assets';
let Sidebar = class Sidebar extends JSPage {
    constructor() {
        super(...arguments);
        this.menu = new Array();
        this.foundPage = true;
        this.Assets = Assets;
    }
    created() {
        super.created();
        Globals.watch(this, (globals) => this.foundPage = globals.isPageFound());
    }
    main() {
        this.menu = [];
        this.menu.push({
            icon: 'mdi-view-dashboard',
            title: 'Principal',
            roles: [User.TYPE_ADMINISTRATOR, User.TYPE_USER],
            to: '/dashboard'
        });
        this.menu.push({
            icon: 'mdi-account-group',
            title: 'Técnicos',
            roles: [User.TYPE_ADMINISTRATOR],
            to: '/users'
        });
        let systemMenu = new Array();
        systemMenu.push({
            icon: 'mdi-account-tie',
            title: 'Administradores',
            roles: [User.TYPE_ADMINISTRATOR],
            to: '/system/administrators'
        });
        systemMenu.push({
            icon: 'mdi-calendar-question',
            title: 'Tipos de eventos',
            roles: [User.TYPE_ADMINISTRATOR],
            to: '/event-types'
        });
        systemMenu.push({
            icon: 'mdi-cog',
            title: 'Configuración',
            roles: [User.TYPE_ADMINISTRATOR],
            to: '/configuration'
        });
        this.menu.push({
            avatar: 'mdi-cog',
            title: 'Sistema',
            group: 'configuration',
            children: systemMenu
        });
    }
};
Sidebar = tslib_1.__decorate([
    Component({
        components: {
            MenuItem,
            MenuItemGroup
        },
        computed: mapState([
            'barColor',
            'sidebarState',
            'excludedPages'
        ]),
        methods: mapMutations({
            setSidebarState: 'setSidebarState'
        })
    })
], Sidebar);
export default Sidebar;
