import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import JSPage from '@/classes/JSPage';
import FullCalendar from '@/components/FullCalendar/FullCalendar.vue';
import Router from '@/classes/Router';
import Calendar from '@/components/Calendar/Calendar.vue';
import moment from 'moment';
import MaterialCard from '@/components/Base/MaterialCard/MaterialCard.vue';
import EventService from '@/services/EventService';
import Event from '@/models/Event';
import InputField from '@/components/InputField/InputField.vue';
import EventTypeService from '@/services/EventTypeService';
import EventType from '@/models/EventType';
import { CBlocker } from '@/components/Blocker/CBlocker';
let DateDetailsPage = class DateDetailsPage extends JSPage {
    constructor() {
        super(...arguments);
        this.eventTypeService = new EventTypeService();
        this.eventTypes = [];
        this.eventService = new EventService();
        this.events = [];
        this.openLocalizations_ = [];
        this.year = moment().year();
        this.month = moment().month();
        this.day = moment().day();
        this.filter = -1;
        this.showDatePicker = false;
        this.datePickerDate = moment().format('YYYY-MM-DD');
        this.datePickerItem = {};
    }
    onRouteChanged(newValue) {
        this.year = parseInt(newValue.query['y']);
        this.year = isNaN(this.year) ? moment().year() : this.year;
        this.month = parseInt(newValue.query['m']);
        this.month = isNaN(this.month) ? moment().month() : this.month;
        this.day = parseInt(newValue.query['d']);
        this.day = isNaN(this.day) ? moment().day() : this.day;
        this.filter = parseInt(newValue.query['f']);
        this.filter = isNaN(this.filter) ? -1 : this.filter;
        if (this.filter === -1)
            Router.removeQueryFromURL('f');
        this.getEventTypes();
        this.getEvents();
        this.getOpenLocalizations();
    }
    getDate() {
        return moment(this.year + '-' + this.month + '-' + this.day, 'YYYY-M-D').locale('es');
    }
    getEventTypes() {
        this.eventTypeService.getAll(undefined, undefined, 1, 9999).subscribe((data) => {
            this.eventTypes = [];
            data.data.forEach((item) => {
                this.eventTypes.push(new EventType(item));
            });
        });
    }
    getEventType(event) {
        if (event.id_event_type__start) {
            const ev = this.eventTypes.find((eventType) => eventType.id == (event.id_event_type__start instanceof Object ? event.id_event_type__start.value : event.id_event_type__start));
            if (ev) {
                return ev;
            }
        }
        return new EventType();
    }
    get eventsTypeStart() {
        return this.eventTypes.filter((eventType) => eventType.type.value == 1 || eventType.type.value == 2 || eventType.type.value == 5);
    }
    eventsTypeAssembly(event) {
        return this.eventTypes.filter((eventType) => this.getEventType(event).type.value == 1 ?
            eventType.type.value == 3 :
            (this.getEventType(event).type.value == 2 ?
                eventType.type.value == 4 :
                eventType.type.value == 3 || eventType.type.value == 4));
    }
    created() {
        this.onRouteChanged(this.$route);
    }
    onBackButtonPressed() {
        Router.removeQueryFromURL('d');
    }
    getTitleDateStr() {
        return this.getDate().format('dddd, DD MMMM YYYY');
    }
    getEvents() {
        let filter = '';
        if (this.filter > 0) {
            filter = 'event_type_start_type==' + this.filter.toString() + ';';
        }
        const start_date = this.getDate().format('YYYY-MM-DD');
        const end_date = this.getDate().add('day', 1).format('YYYY-MM-DD');
        this.eventService.getAll(filter + 'start_ts=ge=' + start_date + ";start_ts=lt=" + end_date, undefined, 1, 999999).subscribe((data) => {
            this.events = [];
            const others = [];
            data.data.forEach((item) => {
                if (item.event_type_start_type.value === 5) {
                    others.push(new Event(item));
                }
                else {
                    this.events.push(new Event(item));
                }
            });
            others.forEach((item) => this.events.push(item));
            if (this.events.length === 0) {
                this.addNewItem();
            }
        });
    }
    getOpenLocalizations() {
        this.eventService.openLocalizations(this.getDate().format('YYYY-MM-DD')).subscribe((localizations) => this.openLocalizations_ = localizations);
    }
    get openLocalizations() {
        var newEvents = [];
        this.events.forEach(ev => {
            if (ev.id <= 0) {
                newEvents.push(ev.localization);
            }
        });
        return this.openLocalizations_.concat(newEvents);
    }
    setEditMode(ev, editMode, index) {
        if (this.user.isUser())
            return;
        if (index !== undefined && this.events[index].editMode === editMode) {
            ev.stopPropagation();
            return;
        }
        let newHeightLocalization = 0;
        let newHeightDescription = 0;
        if (editMode && index !== undefined) {
            newHeightLocalization = document.querySelectorAll('.localization')[index].offsetHeight + 8;
            newHeightDescription = document.querySelectorAll('.description')[index].offsetHeight + 8;
        }
        this.events.forEach((event) => event.editMode = false);
        if (index !== undefined) {
            this.events[index].editMode = editMode;
            if (editMode) {
                const textareaLocalization = document.querySelectorAll('.localization_text')[index];
                if (textareaLocalization) {
                    textareaLocalization.style.height = newHeightLocalization + 'px';
                }
                const textareaDescription = document.querySelectorAll('.description_text')[index];
                if (textareaDescription) {
                    textareaDescription.style.height = newHeightDescription + 'px';
                }
            }
        }
        ev.stopPropagation();
    }
    onEventInput(event) {
        console.log(event);
    }
    onRemoveItem(item) {
        let index = -1;
        this.events.forEach((event, ind) => {
            if (event.id === item.id) {
                index = ind;
            }
        });
        if (index > -1) {
            this.events.splice(index, 1);
        }
    }
    save() {
        if (this.isValid()) {
            this.eventService.addAll(this.events, this.getDate().format('YYYY-MM-DD')).subscribe((events) => {
                this.events = [];
                events.forEach((elem) => this.events.push(new Event(elem)));
                this.$emit('update', this.events);
                Router.removeQueryFromURL('d');
            });
        }
    }
    canAddNew() {
        if (this.events.length > 0) {
            const lastItem = this.events[this.events.length - 1];
            if (!lastItem.id_event_type__start || lastItem.id_event_type__start === undefined) {
                return false;
            }
        }
        return true;
    }
    addNewItem() {
        if (this.canAddNew()) {
            this.events.push(new Event({ start_ts: this.getDate().format('YYYY-MM-DD') }));
            this.$nextTick(() => this.scrollToEnd());
        }
    }
    scrollToEnd() {
        let doc = document;
        doc.querySelector('tbody').scrollTop = doc.querySelector('tbody').scrollHeight;
    }
    isValid() {
        let isValid = true;
        this.events.forEach((item) => {
            if (!item.id_event_type__start || !item.localization || item.localization.trim() === '') {
                isValid = false;
            }
        });
        if (!isValid) {
            CBlocker.showError('Por favor, rellene el tipo y localización en todos los registros');
        }
        return isValid;
    }
    onEditDateItem(item) {
        this.datePickerItem = item;
        this.datePickerDate = this.getDate().format('YYYY-MM-DD');
        this.showDatePicker = true;
    }
    onEditDateClose() {
        this.datePickerItem = {};
        this.showDatePicker = false;
        this.datePickerDate = this.getDate().format('YYYY-MM-DD');
    }
    onEditDateSave() {
        this.datePickerItem.start_ts = moment(this.datePickerDate).format('YYYY-MM-DD 00:00:00');
        this.onEditDateClose();
    }
};
tslib_1.__decorate([
    Prop({ default: false })
], DateDetailsPage.prototype, "value", void 0);
tslib_1.__decorate([
    Watch('$route')
], DateDetailsPage.prototype, "onRouteChanged", null);
DateDetailsPage = tslib_1.__decorate([
    Component({
        components: {
            FullCalendar, Calendar, MaterialCard, InputField
        }
    })
], DateDetailsPage);
export default DateDetailsPage;
