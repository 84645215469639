import BaseService from './BaseService';
import { Observable } from "rxjs";
import { CBlocker } from '@/components/Blocker/CBlocker';
export default class MaintenanceService {
    constructor() {
        this.path = 'maintenances';
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(id) {
        return BaseService.get(this.path + '/' + id);
    }
    add(reg) {
        CBlocker.showLoading();
        const content = Object.assign({}, reg);
        delete (content.id);
        content.days = content.days.filter((day) => day !== undefined).join(',');
        return new Observable((observer) => {
            BaseService.post(this.path, content, undefined, false).subscribe((success) => {
                success.days = success.days.split(',');
                const actions = {};
                success.actions.forEach((action) => {
                    if (actions[action.id_event.strValue] === undefined) {
                        actions[action.id_event.strValue] = {};
                    }
                    actions[action.id_event.strValue][action.day] = {
                        id_event: action.id_event.value,
                        day: action.day,
                        type: action.type.value,
                        note: action.note
                    };
                });
                success.actions = actions;
                observer.next(success);
                CBlocker.showSuccess('Mantenimiento creado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    update(reg) {
        CBlocker.showLoading();
        const content = Object.assign({}, reg);
        delete (content.id);
        content.days = content.days.filter((day) => day !== undefined).join(',');
        return new Observable((observer) => {
            BaseService.put(this.path + '/' + reg.id, content, undefined, false).subscribe((success) => {
                success.days = success.days.split(',');
                const actions = {};
                success.actions.forEach((action) => {
                    if (actions[action.id_event.strValue] === undefined) {
                        actions[action.id_event.strValue] = {};
                    }
                    actions[action.id_event.strValue][action.day] = {
                        id_event: action.id_event.value,
                        day: action.day,
                        type: action.type.value,
                        note: action.note
                    };
                });
                success.actions = actions;
                observer.next(success);
                CBlocker.showSuccess('¡Actualizado!');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    delete(reg) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.delete(this.path + '/' + reg.id, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Mantenimiento eliminado');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getEnums() {
        if (MaintenanceService.enums.length == 0) {
            return BaseService.get(this.path + '/enums');
        }
        else {
            return new Observable((observer) => {
                observer.next(MaintenanceService.enums);
            });
        }
    }
    order(orders) {
        return new Observable((observer) => {
            BaseService.post(this.path + '/order', { orders: orders }, undefined, false).subscribe((success) => {
                observer.next(success);
            }, (error) => {
                observer.error(error);
            });
        });
    }
}
MaintenanceService.enums = new Array();
