import * as tslib_1 from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialMenuInfo from "@/components/Base/MaterialMenuInfo/MaterialMenuInfo.vue";
import moment from 'moment';
import EventCalendar from '../EventCalendar/EventCalendar.vue';
let FullCalendar = class FullCalendar extends Vue {
    constructor() {
        super(...arguments);
        this.weekdays = [1, 2, 3, 4, 5, 6, 0];
    }
    getMonthTitle(month) {
        var monthStr = moment([this.value, month - 1, 1]).locale('es').format('MMMM').toString();
        monthStr = monthStr.substr(0, 1).toUpperCase() + monthStr.substr(1);
        return monthStr;
    }
    getStartDateForMonth(month) {
        return moment([this.value, month - 1, 1]).format('YYYY-MM-DD');
    }
    getEventColor(event) {
        return event.color;
    }
    getDayColor(date, month) {
        var dateWeekday = moment(date).weekday();
        var dateMonth = moment(date).month();
        if (dateMonth != month - 1) {
            return 'grey lighten-2';
        }
        return '';
    }
    isToday(date) {
        return moment().isSame(date, 'day');
    }
    previousYear() {
        this.$emit('input', this.value - 1);
    }
    nextYear() {
        this.$emit('input', this.value + 1);
    }
};
tslib_1.__decorate([
    Prop({ default: moment().year() })
], FullCalendar.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: 'Calendario' })
], FullCalendar.prototype, "title", void 0);
tslib_1.__decorate([
    Prop()
], FullCalendar.prototype, "events", void 0);
tslib_1.__decorate([
    Prop({ default: -1 })
], FullCalendar.prototype, "filter", void 0);
FullCalendar = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard,
            MaterialMenuInfo,
            EventCalendar
        }
    })
], FullCalendar);
export default FullCalendar;
