import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { CBlocker } from '@/components/Blocker/CBlocker';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialUploader from "@/components/Base/MaterialUploader/MaterialUploader.vue";
import Helpers from '@/classes/Helpers';
import Router from '@/classes/Router';
import DatePicker from '../Base/DatePicker/DatePicker.vue';
import moment from 'moment';
let DataTable = class DataTable extends Vue {
    constructor() {
        super(...arguments);
        this.items = [];
        this.itemsPerPageArray = [5, 10, 15, 20];
        this.dialog = false;
        this.editedIndex = -1;
        this.currentPage = 0;
        this.itemsTotal = 0;
        this.pageCount = 0;
        this.itemsPerPage_ = 10;
        this.search = '';
        this.loading = false;
        this.filteredItems = new Map();
        this.editedItems = [];
        this.editedItem = {};
        this.defaultItem = {};
        this.enums = new Map();
        this.foreigns = new Map();
        this.lastClickTime = new Date().getTime();
        this.componentKey = 0;
        this.sortBy = '';
        this.sortDesc = true;
        this.isFilterClosed = true;
        this.refreshTimeInterval = null;
    }
    onDataFilterChanged() {
        this.refresh();
    }
    onRouteChanged(newValue) {
        var oldCurrentPage = this.currentPage;
        if (newValue.query['page']) {
            this.currentPage = parseInt(newValue.query['page']);
        }
        else {
            this.currentPage = 1;
        }
        let orderQuery = false;
        if (newValue.query['order']) {
            orderQuery = newValue.query['order'];
        }
        if (orderQuery) {
            var parts = orderQuery.split('=');
            let newSortBy = parts[0];
            let newSortDesc = parts[1] === 'asc' ? false : true;
            if (this.sortBy !== newSortBy) {
                this.sortBy = newSortBy;
            }
            if (this.sortDesc !== newSortDesc) {
                this.sortDesc = newSortDesc;
            }
        }
        else {
            this.sortBy = '';
            this.sortDesc = true;
        }
        // if( oldCurrentPage !== this.currentPage ) {
        this.refresh();
        // }
    }
    onDataChanged() {
        this.getData();
    }
    onSortByChanged(newValue, oldValue) {
        newValue = newValue !== undefined ? newValue.replace('.strValue', '') : newValue;
        oldValue = oldValue !== undefined ? oldValue.replace('.strValue', '') : oldValue;
        if (newValue !== undefined && newValue !== oldValue) {
            if (!this.sortDesc) {
                if (this.getSort() !== '') {
                    Router.addQueryToURL('order', this.getSort());
                }
                else {
                    Router.removeQueryFromURL('order');
                }
            }
            else {
                this.sortDesc = false;
            }
        }
    }
    onSortDescChanged(newValue) {
        if (this.getSort() !== '') {
            Router.addQueryToURL('order', this.getSort());
        }
        else {
            Router.removeQueryFromURL('order');
        }
    }
    onItemsPerPageChanged() {
        this.itemsPerPage_ = this.itemsPerPage;
        this.refresh();
    }
    onFiltersChanged() {
        this.refresh();
    }
    created() {
        this.itemsPerPage_ = this.itemsPerPage;
        if (!this.headerHasActions()) {
            this.headers.push({ text: '', value: '', align: 'center', sortable: false });
        }
        this.onRouteChanged(this.$router.currentRoute);
        this._selectedItem = this.selectedItem;
        if (this.refreshTime > 0) {
            this.refreshTimeInterval = setInterval(() => this.getData(), this.refreshTime);
        }
    }
    beforeUnmounted() {
        if (this.refreshTimeInterval !== null) {
            clearInterval(this.refreshTimeInterval);
        }
    }
    refresh() {
        this.getEnums();
        this.getForeigns();
        this.getData();
    }
    isSelectedItem(id) {
        return this.items.filter(elem => elem.id === id)[0].isSelected;
    }
    getObjectValueByPath(obj, path, fallback) {
        return Helpers.getObjectValueByPath(obj, path, fallback);
    }
    toLocalDateTime(string) {
        return Helpers.toLocalDateTime(string);
    }
    toLocalDate(string) {
        return Helpers.toLocalDate(string);
    }
    toTime(string) {
        return Helpers.toTime(string);
    }
    toPrice(number) {
        return Helpers.toPrice(number);
    }
    toPercentage(number) {
        return Helpers.toPercentage(number);
    }
    getImageValueByPath(obj, path, fallback) {
        let value = Helpers.getObjectValueByPath(obj, path, fallback);
        if (value && value.includes('://')) {
            return '<img src="' + value + '" style="padding:4px 16px;max-height:80px;max-width:100%;vertical-align:text-top;border-radius:6px;"/>';
        }
        else {
            return '<i class="mdi ' + value + '"></i>';
        }
    }
    getSort() {
        if (this.sortBy !== '' && this.sortBy !== undefined && this.sortDesc !== undefined) {
            return this.sortBy.replace('.strValue', '') + '=' + (this.sortDesc ? 'desc' : 'asc');
        }
        return '';
    }
    getData() {
        if (this.service != null) {
            var localdatafilter = this.datafilter;
            for (let value of this.filteredItems.values()) {
                if (localdatafilter == '')
                    localdatafilter = value;
                else
                    localdatafilter = localdatafilter + ";" + value;
            }
            let sort = this.getSort();
            var order = this.dataorder;
            if (sort !== '') {
                order = sort;
                // order = this.dataorder ? this.dataorder + ';' + sort : sort;
            }
            this.service.getAll(localdatafilter, order, this.currentPage, this.itemsPerPage_).subscribe((items) => {
                if (this.itemsClass) {
                    this.items = [];
                    items.data.forEach((item) => this.items.push(new this.itemsClass(item)));
                }
                else {
                    this.items = items.data;
                }
                this.itemsTotal = items.total;
                if (this.currentPage > items.last_page) {
                    this.currentPage = items.last_page;
                }
                else {
                    this.currentPage = items.current_page;
                }
                Router.addQueryToURL('page', this.currentPage);
                this.pageCount = items.last_page;
                this.itemsPerPage_ = parseInt(items.per_page);
                this.highlightSelectedItem();
            }, (error) => {
                CBlocker.showError(error.message);
            });
        }
        else {
            if (this.data) {
                this.items = this.data;
                this.itemsTotal = this.data.length;
                this.currentPage = 1;
                Router.addQueryToURL('page', this.currentPage);
                this.pageCount = Math.ceil(this.itemsTotal / this.itemsPerPage_);
                this.itemsPerPage_ = this.itemsPerPage;
                this.highlightSelectedItem();
            }
        }
    }
    highlightSelectedItem() {
        if (this._selectedItem) {
            let selected = this.items.filter((elem) => elem.id == this._selectedItem.id)[0];
            if (selected) {
                selected.isSelected = true;
                this.forceUpdate();
            }
        }
    }
    newItem() {
        if (this.tableType == 'complex') {
            this.$router.push(this.url + '/new');
        }
        else {
            if (this.itemsClass) {
                this.editedItem = new this.itemsClass(this.editedItem);
            }
        }
        this.$emit('onNewItem', this.editedItem);
    }
    formTitle() {
        return this.editedIndex === -1 ? 'Nuevo' : (this.canUpdate ? 'Editar' : 'Información');
    }
    editItem(item) {
        if (this.tableType == 'complex')
            this.$router.push(this.url + '/' + item.id);
        else {
            delete (item.isSelected);
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            if (this.itemsClass) {
                this.editedItem = new this.itemsClass(this.editedItem);
            }
            this.$emit('onEditingItem', this.editedItem);
            this.dialog = true || this.close();
        }
    }
    deleteItem(item) {
        const index = this.items.indexOf(item);
        CBlocker.showConfirm(this.deletionMessage !== '' ? this.deletionMessage : '¿Está seguro de que desea eliminar este elemento?', () => {
            this.service.delete(item).subscribe((item) => {
                this.items.splice(index, 1);
                this.$emit('changed');
                this.refresh();
            }, (error) => {
            });
        });
    }
    close() {
        this.dialog = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            if (this.itemsClass) {
                this.editedItem = new this.itemsClass(this.editedItem);
            }
        });
        this.editedIndex = -1;
    }
    forceUpdate() {
        this.componentKey++;
    }
    handleClick(item) {
        this.items.forEach(elem => elem.isSelected = false);
        this.items.filter(elem => elem.id === item.id)[0].isSelected = true;
        this._selectedItem = this.items.filter(elem => elem.id === item.id)[0];
        this.forceUpdate();
        let actualClickTime = new Date().getTime();
        this.$emit('onDataTableSelect', item);
        if (actualClickTime - this.lastClickTime < 300) {
            this.$emit('onDblClick', item);
            this.handleDoubleClick(item);
        }
        this.lastClickTime = actualClickTime;
    }
    handleDoubleClick(item) {
        this.editItem(item);
    }
    handleSortByChange(item) {
        this.sortBy = item;
    }
    handleSortDescChange(item) {
        this.sortDesc = item;
    }
    avoidLocalSort(items, groupBy, groupDesc) {
        return items;
    }
    save() {
        this.$emit('preSave', this.editedItem);
        if (this.editedItem.image_FILE) {
            this.editedItem.image = this.editedItem.image_FILE;
            delete (this.editedItem.image_FILE);
        }
        if (this.editedIndex > -1) {
            //Edit
            this.service.update(this.editedItem).subscribe((item) => {
                this.editedIndex = -1;
                this.getData();
                this.close();
                this.$emit('changed');
            }, (error) => { });
        }
        else {
            // if (this.datafilter){
            // 	this.editedItem.datafilter=this.datafilter
            // }
            //Add
            this.service.add(this.editedItem).subscribe((item) => {
                this.getData();
                this.close();
                this.$emit('changed');
            }, (error) => { });
        }
    }
    nextPage() { }
    previousPage() { }
    goToPage(page) {
        this.currentPage = page;
        this.getData();
        Router.addQueryToURL('page', page);
    }
    updateItemsPerPage(number) {
        var num_pags = this.numberOfPages();
        var prev = this.itemsPerPage_;
        var elems_prev = this.currentPage * prev;
        this.itemsPerPage_ = number;
        this.pageCount = this.numberOfPages();
        var cp = Math.ceil(elems_prev / this.pageCount);
        if (cp >= this.pageCount)
            this.currentPage = this.pageCount;
        else if (cp < 1)
            this.currentPage = 1;
        else
            this.currentPage = cp;
        this.getData();
    }
    numberOfPages() {
        return Math.ceil(this.itemsTotal / this.itemsPerPage_);
    }
    change(filter) {
        if (!filter.toggle) {
            filter.toggle = true;
        }
    }
    onFilterKeyPressed(key, filter) {
        if (key.keyCode === 13) {
            this.sendMessage(filter);
        }
    }
    sendMessage(filter) {
        var enumFilter = '';
        if (filter.type == 'text' && filter.content !== '') {
            filter.toggle = false;
            if (filter.content !== '') {
                this.filteredItems.set(filter.value, filter.value + "=like=" + "%" + filter.content + "%");
            }
            else {
                this.filteredItems.delete(filter.value);
            }
            this.getData();
        }
        else if (filter.type == 'enum') {
            enumFilter = '';
            if (filter.content.length > 0) {
                for (let value of filter.content.values()) {
                    if (enumFilter == '')
                        enumFilter = value;
                    else
                        enumFilter = enumFilter + ',' + value;
                }
                enumFilter = filter.value + '=in=(' + enumFilter + ')';
            }
            this.filteredItems.set(filter.value, enumFilter);
            this.getData();
        }
        else if (filter.type == 'foreign') {
            enumFilter = '';
            if (filter.content.length > 0) {
                for (let value of filter.content.values()) {
                    if (enumFilter == '')
                        enumFilter = value;
                    else
                        enumFilter = enumFilter + ',' + value;
                }
                enumFilter = filter.value + '=in=(' + enumFilter + ')';
            }
            this.filteredItems.set(filter.value, enumFilter);
            this.getData();
        }
        else if (filter.type === 'date') {
            enumFilter = '';
            if (filter.content != '' && filter.content !== undefined) {
                var fromDate = moment(filter.content, 'YYYY-MM-DD');
                this.filteredItems.set(filter.value, filter.value + "=ge=" + fromDate.format('YYYY-MM-DD 00:00:00') + ';' +
                    filter.value + "=le=" + fromDate.format('YYYY-MM-DD 23:59:59 '));
            }
            else {
                this.filteredItems.delete(filter.value);
            }
            this.getData();
        }
    }
    clearMessage(filter) {
        this.filteredItems.delete(filter.value);
        this.getData();
        filter.toggle = true;
    }
    getForeigns() {
        this.foreigns.clear();
        if (this.filters) {
            this.filters.forEach((filter) => {
                if (filter.type == 'foreign') {
                    var ff = filter;
                    filter.foreignService.getAll(filter.filter, filter.order, undefined, 99999).subscribe((items) => {
                        if (filter.class) {
                            this.foreigns.set(filter.value, items.data.map((elem) => new filter.class(elem)));
                        }
                        else {
                            this.foreigns.set(filter.value, items.data);
                        }
                    });
                }
            });
        }
    }
    getEnums() {
        this.enums.clear();
        if (this.filters) {
            this.filters.forEach((filter) => {
                if (filter.type == 'enum') {
                    var f = filter;
                    this.service.getEnums(filter.enum_name).subscribe((enum_vals) => {
                        this.enums.set(f.enum_name, enum_vals[f.enum_name]);
                    }, (error) => {
                    });
                }
            });
        }
    }
    toggleFilter() {
        this.isFilterClosed = !this.isFilterClosed;
    }
    headerHasActions() {
        return this.headers.filter((header) => header.value == 'actions').length > 0;
    }
};
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "service", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "foreignService", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "id", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "url", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "headers", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "filters", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "tableName", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "datafilter", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "dataorder", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DataTable.prototype, "canAdd", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DataTable.prototype, "canUpdate", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DataTable.prototype, "canDelete", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DataTable.prototype, "canView", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataTable.prototype, "showHeader", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], DataTable.prototype, "pagination", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataTable.prototype, "data", void 0);
tslib_1.__decorate([
    Prop({ default: 'blue-grey' })
], DataTable.prototype, "color", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "selection", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataTable.prototype, "allowSelect", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataTable.prototype, "allowRemoveFromList", void 0);
tslib_1.__decorate([
    Prop({ default: 'simple' })
], DataTable.prototype, "tableType", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "selectedItem", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataTable.prototype, "dense", void 0);
tslib_1.__decorate([
    Prop({ default: 0 })
], DataTable.prototype, "refreshTime", void 0);
tslib_1.__decorate([
    Prop({ default: 10 })
], DataTable.prototype, "itemsPerPage", void 0);
tslib_1.__decorate([
    Prop()
], DataTable.prototype, "itemsClass", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], DataTable.prototype, "deletionMessage", void 0);
tslib_1.__decorate([
    Watch('datafilter')
], DataTable.prototype, "onDataFilterChanged", null);
tslib_1.__decorate([
    Watch('$route')
], DataTable.prototype, "onRouteChanged", null);
tslib_1.__decorate([
    Watch('data')
], DataTable.prototype, "onDataChanged", null);
tslib_1.__decorate([
    Watch('sortBy')
], DataTable.prototype, "onSortByChanged", null);
tslib_1.__decorate([
    Watch('sortDesc')
], DataTable.prototype, "onSortDescChanged", null);
tslib_1.__decorate([
    Watch('itemsPerPage')
], DataTable.prototype, "onItemsPerPageChanged", null);
tslib_1.__decorate([
    Watch('filters')
], DataTable.prototype, "onFiltersChanged", null);
DataTable = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard,
            MaterialUploader,
            DatePicker
        }
    })
], DataTable);
export default DataTable;
