import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import { User } from '@/models/User';
import UserService from '@/services/UserService';
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import UserAccountTab from './Tabs/UserAccountTab/UserAccountTab.vue';
import { EnumEntry } from '@/models/EnumEntry';
import Router from '@/classes/Router';
import JSPage from '@/classes/JSPage';
let UserPage = class UserPage extends JSPage {
    constructor() {
        super(...arguments);
        this.userService = new UserService();
        this.user_ = new User();
        this.id_company = parseInt(Router.getParam('id_company', 0));
        this.enumType = {};
        this.enumStatus = {};
        this.status = false;
        this.tabs = new Array();
        this.tab = '';
    }
    onUserStatusChanged() {
        this.status = (this.user_.status && this.user_.status.value === 1);
    }
    onStatusChanged(newValue) {
        if (this.enumStatus.length > 0) {
            this.user_.status = (newValue ? this.enumStatus[0] : this.enumStatus[1]);
        }
    }
    OnUserChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
        this.getEnums();
    }
    main() {
        if (this.user.isUser()) {
            this.$router.back();
        }
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Cuenta', icon: 'mdi-account', href: 'main-tab' });
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getUser();
        }
        else {
            if (id === 'new') {
                this.user_.type = new EnumEntry(parseInt(this.$attrs.type));
                this.loadTabs();
            }
            else {
                this.cancel();
            }
        }
    }
    getEnums() {
        this.userService.getEnums().subscribe((enums) => {
            this.enumType = enums['Type'];
            this.enumStatus = enums['Status'];
            if (!this.user_.id) {
                this.user_.type = this.enumType.filter(elem => elem.value === parseInt(this.$attrs.type))[0];
                this.user_.status = this.enumStatus[0];
            }
        });
    }
    getCardColor() {
        if (!this.user_ || !this.user_.status || this.user_.status.value == 2) {
            return 'blue-grey';
        }
        else {
            if (this.user_.isAdmin()) {
                return 'admin';
            }
            else {
                return 'user';
            }
        }
    }
    getUser() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.userService.get(id).subscribe((user) => {
                this.user_ = new User(user);
                this.loadTabs();
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.user_.id) {
            this.userService.add(this.user_).subscribe((user) => {
                this.user_ = new User(user);
            }, (error) => { });
        }
        else {
            this.userService.update(this.user_).subscribe((user) => {
                this.user_ = new User(user);
            }, (error) => { });
        }
    }
    cancel() {
        this.$router.back();
    }
    onUserUpdate(event) {
        this.user_ = event;
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('user_.status')
], UserPage.prototype, "onUserStatusChanged", null);
tslib_1.__decorate([
    Watch('status')
], UserPage.prototype, "onStatusChanged", null);
tslib_1.__decorate([
    Watch('user_')
], UserPage.prototype, "OnUserChanged", null);
UserPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, Tabs,
            UserAccountTab
        }
    })
], UserPage);
export default UserPage;
