import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import UserService from '@/services/UserService';
import { User } from '@/models/User';
import JSPage from '@/classes/JSPage';
let UsersPage = class UsersPage extends JSPage {
    constructor() {
        super(...arguments);
        this.userService = new UserService();
        this.userType = -1;
        this.userTypeConfig = [];
    }
    onUserTypeChanged() {
        this.userType = parseInt(this.$attrs.type);
    }
    created() {
        super.created();
    }
    main() {
        if (this.user.isUser()) {
            this.$router.back();
        }
        this.setUserTypes();
        this.userType = parseInt(this.$attrs.type);
    }
    getUserType() {
        return this.userTypeConfig.filter(elem => elem.type === this.userType)[0];
    }
    setUserTypes() {
        var admin = {
            type: User.TYPE_ADMINISTRATOR,
            title: 'Administradores',
            color: 'admin',
            url: 'administrators',
            headers: [
                { text: 'Nombre', value: 'name', align: 'start' },
                { text: 'Email', value: 'email' },
                { text: 'Estado', value: 'status.strValue', align: 'end' },
                { text: '', value: 'actions', align: 'end', sortable: false }
            ],
            filters: [
                { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
                { text: 'Email', value: 'email', content: '', toggle: true, type: 'text' }
            ]
        };
        var user = {
            type: User.TYPE_USER,
            title: 'Técnicos',
            color: 'user',
            url: 'users'
        };
        user.headers = [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Email', value: 'email' }
        ];
        if (this.user.isAdmin()) {
            user.headers.push({ text: 'Empresa', value: 'id_company.strValue' });
        }
        user.headers.push({ text: 'Rol', value: 'id_role.strValue' }, { text: 'Estado', value: 'status.strValue', align: 'end' }, { text: '', value: 'actions', align: 'end', sortable: false });
        user.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'Email', value: 'email', content: '', toggle: true, type: 'text' }
        ];
        this.userTypeConfig = [];
        this.userTypeConfig.push(admin, user);
    }
};
tslib_1.__decorate([
    Watch('$attrs.type')
], UsersPage.prototype, "onUserTypeChanged", null);
UsersPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], UsersPage);
export default UsersPage;
