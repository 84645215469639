import Assets from "@/classes/Assets";
import moment from "moment";
export default class Event {
    constructor(data) {
        this.id = -1;
        this.localization = '';
        this.start_title = '';
        this.start_description = '';
        this.start_ts = moment().format('YYYY-MM-DD');
        this._notFoundStartIcon = false;
        this._DEFAULT_START_ICON = Assets.getImage('default_icon.png');
        this.end_title = '';
        this.end_description = '';
        this._notFoundEndIcon = false;
        this._DEFAULT_END_ICON = Assets.getImage('default_icon.png');
        this.has_shutdown = true;
        this.editMode = false;
        if (data) {
            Object.assign(this, data);
        }
    }
    get startTS() {
        return moment(this.start_ts, 'YYYY-MM-DD HH:mm').toDate();
    }
    get endTS() {
        if (this.end_ts) {
            return moment(this.end_ts, 'YYYY-MM-DD HH:mm').toDate();
        }
        return undefined;
    }
    get startDay() {
        return moment(this.start_ts, 'YYYY-MM-DD HH:mm').format('DD');
    }
    get startDateStr() {
        return moment(this.start_ts, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY');
    }
    get startDateStrBD() {
        return moment(this.start_ts, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
    }
    get StartTimeStr() {
        return moment(this.start_ts, 'YYYY-MM-DD HH:mm').format('HH:mm');
    }
    get endDateStr() {
        if (this.end_ts) {
            return moment(this.end_ts, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY');
        }
        return '';
    }
    get endTimeStr() {
        if (this.end_ts) {
            return moment(this.end_ts, 'YYYY-MM-DD HH:mm').format('HH:mm');
        }
        return '';
    }
    getStartIconSrc() {
        return (this.event_type_start_icon && this.event_type_start_icon !== '' && !this._notFoundStartIcon) ?
            this.event_type_start_icon :
            this.getDefaultStartIcon();
    }
    getDefaultStartIcon() {
        return this._DEFAULT_START_ICON;
    }
    catchStartIconError() {
        this._notFoundStartIcon = true;
    }
    toNormalize() {
        let event = Object.assign({}, this);
        delete (event.event_type_start_icon);
        delete (event.event_type_end_icon);
        delete (event.editMode);
        delete (event._notFoundStartIcon);
        delete (event._DEFAULT_START_ICON);
        delete (event._notFoundEndIcon);
        delete (event._DEFAULT_END_ICON);
        event.has_shutdown = event.has_shutdown ? 1 : 0;
        if (event.id_event_type__start instanceof Object) {
            event.id_event_type__start = event.id_event_type__start.value;
        }
        if (event.start_status instanceof Object) {
            event.start_status = event.start_status.value;
        }
        if (event.id_event_type__end instanceof Object) {
            event.id_event_type__end = event.id_event_type__end.value;
        }
        if (event.end_status instanceof Object) {
            event.end_status = event.end_status.value;
        }
        return event;
    }
}
Event.STATUS_UNCONFIRMED = 0;
Event.STATUS_CONFIRMED = 1;
