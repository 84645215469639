import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialMenuInfo from "@/components/Base/MaterialMenuInfo/MaterialMenuInfo.vue";
import moment from 'moment';
import EventService from '@/services/EventService';
import Event from "@/models/Event";
let EventCalendar = class EventCalendar extends Vue {
    constructor() {
        super(...arguments);
        this.weekdays = [1, 2, 3, 4, 5, 6, 0];
        this.eventService = new EventService();
        this.events = [];
    }
    onValueChanged() {
        this.getEvents();
    }
    created() {
        this.getEvents();
    }
    getTitle() {
        var title = moment(this.value).locale('es').format('MMMM - YYYY').toString();
        title = title.substr(0, 1).toUpperCase() + title.substr(1);
        return title;
    }
    getStartDateForMonth(month) {
        return moment(this.value).locale('es').set('date', 1).format('YYYY-MM-DD');
    }
    getEventColor(event) {
        return event.color;
    }
    getMonthTitle() {
        var monthStr = moment(this.value).locale('es').format('MMMM').toString();
        monthStr = monthStr.substr(0, 1).toUpperCase() + monthStr.substr(1);
        return monthStr;
    }
    getEvents() {
        const start_date = moment(this.value).locale('es').set('date', 1).format('YYYY-MM-DD');
        const end_date = moment(this.value).locale('es').set('date', 1).add(1, 'month').format('YYYY-MM-DD');
        this.eventService.getAll('start_ts=ge=' + start_date + ";start_ts=lt=" + end_date, undefined, 1, 999999).subscribe((data) => {
            this.events = [];
            const others = [];
            data.data.forEach((item) => {
                if (item.event_type_start_type.value === 5) {
                    others.push(new Event(item));
                }
                else {
                    this.events.push(new Event(item));
                }
            });
            others.forEach((item) => this.events.push(item));
        });
    }
    getEventsFor(date) {
        return this.events.filter((item) => {
            return (moment(item.startTS).format('YYYY-MM-DD') === date) &&
                (this.filter != -1 ? item.id_event_type__start.value == this.filter : true);
        });
    }
    getDayColor(date) {
        var dateWeekday = moment(date).weekday();
        var dateMonth = moment(date).month();
        return '';
    }
    isToday(date) {
        return moment().isSame(date, 'day');
    }
    previousMonth() {
        this.$emit('input', moment(this.value, 'YYYY-MM-DD').subtract(1, 'M').format('YYYY-MM-DD'));
    }
    nextMonth() {
        this.$emit('input', moment(this.value, 'YYYY-MM-DD').add(1, 'M').format('YYYY-MM-DD'));
    }
};
tslib_1.__decorate([
    Prop({ default: Date() })
], EventCalendar.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: -1 })
], EventCalendar.prototype, "filter", void 0);
tslib_1.__decorate([
    Watch('value'),
    Watch('filter')
], EventCalendar.prototype, "onValueChanged", null);
EventCalendar = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard,
            MaterialMenuInfo,
        }
    })
], EventCalendar);
export default EventCalendar;
