import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import { User } from '@/models/User';
import UserService from '@/services/UserService';
import Router from '@/classes/Router';
import JSPage from '@/classes/JSPage';
let UserAccountTab = class UserAccountTab extends JSPage {
    constructor() {
        super(...arguments);
        this.id_company = parseInt(Router.getParam('id_company', 0));
        this.userService = new UserService();
        this.showPass = false;
    }
    created() {
        super.created();
    }
    main() { }
    update() {
        let u = this.user_;
        if (u) {
            if (!u.id) {
                this.userService.add(u).subscribe((user_) => {
                    this.$router.replace(user_.id.toString());
                }, (error) => {
                });
            }
            else {
                this.userService.update(u).subscribe((user_) => {
                    this.$emit('input', new User(user_));
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], UserAccountTab.prototype, "user_", void 0);
UserAccountTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialAvatar, InputField
        }
    })
], UserAccountTab);
export default UserAccountTab;
