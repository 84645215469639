import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import JSPage from '@/classes/JSPage';
import FullCalendar from '@/components/FullCalendar/FullCalendar.vue';
import Router from '@/classes/Router';
import Calendar from '@/components/Calendar/Calendar.vue';
import moment from 'moment';
import DateDetailsPage from '../DateDetails/DateDetailsPage.vue';
import EventTypeService from '@/services/EventTypeService';
import EventType from '@/models/EventType';
import EventsPrintDialog from './EventsPrintDialog/EventsPrintDialog.vue';
import InputField from '@/components/InputField/InputField.vue';
import ObservationService from '@/services/ObservationService';
import EventService from '@/services/EventService';
import SearchDetailsPage from '../SearchDetails/SearchDetailsPage.vue';
let DashboardPage = class DashboardPage extends JSPage {
    constructor() {
        super(...arguments);
        this.year = moment().year();
        this.month = -1;
        this.day = -1;
        this.filter = -1;
        this.search = '';
        this.searchYear = moment().year();
        this.showInfo = false;
        this.showSearchDetails = false;
        this.showPrintDialog = false;
        this.refreshes = 0;
        this.eventTypeService = new EventTypeService();
        this.eventTypes = [];
        this.observationService = new ObservationService();
        this.observation = {};
        this.eventService = new EventService();
        this.events = [];
    }
    onYearChanged() {
        if (this.year < 2021) {
            this.year = 2021;
        }
        if (this.year > 2100) {
            this.year = 2021;
        }
        Router.addQueryToURL('y', this.year.toString());
    }
    onMonthChanged() {
        if (this.month < 1 || this.month > 12) {
            this.month = -1;
        }
        if (this.month > -1) {
            Router.addQueryToURL('m', this.month.toString());
        }
        else {
            Router.removeQueryFromURL('m');
        }
    }
    onDayChanged() {
        if (!moment(this.year + '-' + this.month + '-' + this.day, 'YYYY-M-D').isValid()) {
            this.day = -1;
        }
        if (this.day > -1) {
            Router.addQueryToURL('d', this.day.toString());
        }
        else {
            Router.removeQueryFromURL('d');
        }
    }
    OnFilterChanged() {
        if (this.filter > -1) {
            Router.addQueryToURL('f', this.filter.toString());
        }
        else {
            Router.removeQueryFromURL('f');
        }
    }
    onSearchClicked() {
        if (this.search.trim() != '') {
            Router.addQueryToURL('s', this.search);
            Router.addQueryToURL('sy', this.searchYear.toString());
        }
        else {
            Router.removeQueryFromURL('s');
            Router.removeQueryFromURL('sy');
        }
    }
    onRouteChanged(newValue) {
        this.year = parseInt(newValue.query['y']);
        this.year = isNaN(this.year) ? moment().year() : this.year;
        this.month = parseInt(newValue.query['m']);
        this.month = isNaN(this.month) ? -1 : this.month;
        if (this.month === -1) {
            Router.removeQueryFromURL('m');
            this.observation = {};
        }
        else {
            this.loadObservations();
        }
        this.day = parseInt(newValue.query['d']);
        this.day = isNaN(this.day) ? -1 : this.day;
        if (this.day === -1)
            Router.removeQueryFromURL('d');
        this.filter = parseInt(newValue.query['f']);
        this.filter = isNaN(this.filter) ? -1 : this.filter;
        if (this.filter === -1)
            Router.removeQueryFromURL('f');
        Router.addQueryToURL('y', this.year.toString());
        this.showInfo = this.month !== -1 && this.day !== -1;
        if (newValue.query['s'] && newValue.query['sy']) {
            this.search = newValue.query['s'].toString();
            if (isNaN(this.searchYear)) {
                this.searchYear = moment().year();
            }
            else {
                this.searchYear = parseInt(newValue.query['sy'].toString());
            }
            this.showSearchDetails = true;
            this.refreshes++;
        }
        else {
            this.search = '';
            this.searchYear = moment().year();
            this.showSearchDetails = false;
            Router.removeQueryFromURL('s');
            Router.removeQueryFromURL('sy');
        }
    }
    created() {
        super.created();
        this.getEventTypes();
        this.onRouteChanged(this.$route);
    }
    main() { }
    getEventTypes() {
        this.eventTypeService.getAll(undefined, "type=ASC", 1, 9999).subscribe((data) => {
            this.eventTypes = [];
            data.data.forEach((item) => {
                this.eventTypes.push(new EventType(item));
            });
        });
    }
    get types() {
        var tValues = new Map();
        this.eventTypes.forEach(ev => {
            if (!tValues.has(ev.type.value)) {
                tValues.set(ev.type.value, ev.type.strValue);
            }
        });
        return tValues;
    }
    loadObservations() {
        this.observationService.get(this.year * 100 + this.month).subscribe((observation) => this.observation = observation);
    }
    onFullCalendarClick(event) {
        const actual = moment(event.date, 'YYYY-MM-DD');
        this.year = actual.year();
        this.month = actual.month() + 1;
        window.scrollTo(0, 0);
    }
    onDateClicked(event) {
        this.year = event.year;
        this.month = event.month;
        this.day = event.day;
    }
    onBackButtonPressed() {
        if (this.day !== -1) {
            this.day = -1;
        }
        else if (this.month !== -1) {
            this.month = -1;
        }
    }
    onEventsUpdated() {
        this.refreshes++;
    }
    setFilter(id) {
        if (this.filter === id) {
            this.filter = -1;
        }
        else {
            this.filter = id;
        }
    }
    onMaintenanceButtonPressed() {
        let query = '';
        if (this.month !== -1) {
            query = '?y=' + this.year.toString() + '&m=' + this.month.toString();
        }
        Router.goTo('/maintenance' + query);
    }
    onEventsPrintButtonPressed() {
        this.showPrintDialog = true;
    }
    onObservationsSaveButtonPressed() {
        this.observation.date = this.year * 100 + this.month;
        if (this.observation.id) {
            this.observationService.update(this.observation).subscribe((observation) => this.observation = observation);
        }
        else {
            this.observationService.add(this.observation).subscribe((observation) => this.observation = observation);
        }
    }
};
tslib_1.__decorate([
    Watch('year')
], DashboardPage.prototype, "onYearChanged", null);
tslib_1.__decorate([
    Watch('month')
], DashboardPage.prototype, "onMonthChanged", null);
tslib_1.__decorate([
    Watch('day')
], DashboardPage.prototype, "onDayChanged", null);
tslib_1.__decorate([
    Watch('filter')
], DashboardPage.prototype, "OnFilterChanged", null);
tslib_1.__decorate([
    Watch('$route')
], DashboardPage.prototype, "onRouteChanged", null);
DashboardPage = tslib_1.__decorate([
    Component({
        components: {
            FullCalendar, Calendar, DateDetailsPage, EventsPrintDialog, InputField, SearchDetailsPage
        }
    })
], DashboardPage);
export default DashboardPage;
