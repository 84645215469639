import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialMenuInfo from "@/components/Base/MaterialMenuInfo/MaterialMenuInfo.vue";
import moment from 'moment';
import EventService from '@/services/EventService';
import Event from "@/models/Event";
import Router from '@/classes/Router';
import Assets from '@/classes/Assets';
let Calendar = class Calendar extends Vue {
    constructor() {
        super(...arguments);
        this.year_ = Router.getQueryVar('y');
        this.month_ = Router.getQueryVar('m');
        this.filter_ = Router.getQueryVar('f');
        this.weekdays = [1, 2, 3, 4, 5, 6, 0];
        this.eventService = new EventService();
        this.events = [];
        this.Assets = Assets;
    }
    onValueChanged() {
        this.getEvents();
    }
    onRouteChanged() {
        this.year_ = Router.getQueryVar('y');
        this.month_ = Router.getQueryVar('m');
        this.filter_ = Router.getQueryVar('f', -1);
        this.getEvents();
    }
    onRefreshesChanged() {
        this.getEvents();
    }
    created() {
        this.year_ = this.year !== -1 ? this.year : this.year_;
        this.month_ = this.month !== -1 ? this.month : this.month_;
        this.filter_ = Router.getQueryVar('f', -1);
        this.getEvents();
    }
    getTitle() {
        var title = this.getStartDate().format('MMMM - YYYY').toString();
        title = title.substr(0, 1).toUpperCase() + title.substr(1);
        return title;
    }
    getStartDate() {
        return moment(this.year_ + '-' + this.month_ + '-01', 'YYYY-M-D').locale('es');
    }
    getStartDateForMonth() {
        return this.getStartDate().format('YYYY-MM-DD');
    }
    getEventColor(event) {
        return event.color;
    }
    getEvents() {
        const start_date = this.getStartDateForMonth();
        const end_date = this.getStartDate().add(1, 'month').format('YYYY-MM-DD');
        this.eventService.getAll('start_ts=ge=' + start_date + ";start_ts=lt=" + end_date, undefined, 1, 999999).subscribe((data) => {
            this.events = [];
            const others = [];
            data.data.forEach((item) => {
                if (item.event_type_start_type.value === 5) {
                    others.push(new Event(item));
                }
                else {
                    this.events.push(new Event(item));
                }
            });
            others.forEach((item) => this.events.push(item));
        });
    }
    getEventsFor(date) {
        return this.events.filter((item) => {
            return (moment(item.startTS).format('YYYY-MM-DD') === date) &&
                (this.filter_ != -1 ? item.event_type_start_type.value == this.filter_ : true);
        });
    }
    getEventsCountFor(date) {
        return this.getEventsFor(date).length;
    }
    getDayColor(date) {
        var dateWeekday = moment(date).weekday();
        var dateMonth = moment(date).month() + 1;
        return '';
    }
    isToday(date) {
        return moment().isSame(date, 'day');
    }
    previousMonth() {
        if (this.year === 2021 && this.month === 1) {
            return;
        }
        var prev = this.getStartDate().subtract(1, 'M');
        Router.addQueryToURL('m', prev.month() + 1);
        Router.addQueryToURL('y', prev.year());
    }
    nextMonth() {
        if (this.year === 2100 && this.month === 12) {
            return;
        }
        var next = this.getStartDate().add(1, 'M');
        Router.addQueryToURL('m', next.month() + 1);
        Router.addQueryToURL('y', next.year());
    }
};
tslib_1.__decorate([
    Prop({ default: -1 })
], Calendar.prototype, "year", void 0);
tslib_1.__decorate([
    Prop({ default: -1 })
], Calendar.prototype, "month", void 0);
tslib_1.__decorate([
    Prop({ default: 0 })
], Calendar.prototype, "refreshes", void 0);
tslib_1.__decorate([
    Watch('year'),
    Watch('month'),
    Watch('filter')
], Calendar.prototype, "onValueChanged", null);
tslib_1.__decorate([
    Watch('$route')
], Calendar.prototype, "onRouteChanged", null);
tslib_1.__decorate([
    Watch('refreshes')
], Calendar.prototype, "onRefreshesChanged", null);
Calendar = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard,
            MaterialMenuInfo,
        }
    })
], Calendar);
export default Calendar;
