import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import JSPage from '@/classes/JSPage';
import moment from 'moment';
import MaterialCard from '@/components/Base/MaterialCard/MaterialCard.vue';
import DatePicker from '@/components/Base/DatePicker/DatePicker.vue';
import EventService from '@/services/EventService';
import Event from '@/models/Event';
import Router from '@/classes/Router';
import EventTypeService from '@/services/EventTypeService';
import EventType from '@/models/EventType';
import { EnumEntry } from '@/models/EnumEntry';
let EventsPrintDialog = class EventsPrintDialog extends JSPage {
    constructor() {
        super(...arguments);
        this.start = moment().startOf('month').toDate();
        this.end = moment().endOf('month').toDate();
        this.eventService = new EventService();
        this.events = [];
        this.eventTypeService = new EventTypeService();
        this.eventTypes = [];
        this.enumType = {};
        this.type = -1;
    }
    onDateChanged() {
        this.loadData();
    }
    onValueChanged() {
        if (this.value) {
            if (Router.getQueryVar('m', false)) {
                const m = Router.getQueryVar('m');
                this.start = moment().set('month', m - 1).startOf('month').toDate();
                this.end = moment().set('month', m - 1).endOf('month').toDate();
            }
        }
        else {
            this.events = [];
            this.type = -1;
        }
    }
    onTypesChanged() {
        this.$forceUpdate();
    }
    created() {
        super.created();
        this.getEnums();
        this.getEventTypes();
    }
    main() { }
    loadData() {
        let filter = 'event_type__start.type=in=(1,2);';
        const start_date = moment(this.start).format('YYYY-MM-DD');
        const end_date = moment(this.end).format('YYYY-MM-DD');
        this.eventService.getAll(filter + 'start_ts=ge=' + start_date + ";start_ts=le=" + end_date, 'start_ts=ASC', 1, 999999).subscribe((data) => {
            this.events = [];
            data.data.forEach((item) => {
                this.events.push(new Event(item));
            });
        });
    }
    getFilteredEvents() {
        if (this.type > 0) {
            return this.events.filter((event) => this.type === event.event_type_start_type.value);
        }
        return this.events;
    }
    getEnums() {
        this.eventTypeService.getEnums().subscribe((enums) => {
            this.enumType = enums['Type'].filter((value) => [1, 2].includes(value.value));
            this.enumType.unshift(new EnumEntry());
        });
    }
    getEventTypes() {
        this.eventTypeService.getAll(undefined, undefined, 1, 9999).subscribe((data) => {
            this.eventTypes = [];
            data.data.forEach((item) => {
                this.eventTypes.push(new EventType(item));
            });
        });
    }
    getEventType(event) {
        if (event.id_event_type__end) {
            const ev = this.eventTypes.find((eventType) => eventType.id == event.id_event_type__end);
            if (ev) {
                return ev;
            }
        }
        return new EventType();
    }
    onPrintButtonPressed() {
        const printer = window.open('', '', 'left=0,top=0,width=1000,height=700,toolbar=0,scrollbars=0,status=0');
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }
        const table = document.getElementById('events').innerHTML;
        printer.document.write(`<!DOCTYPE html>
            <html>
                <head>
                    ${stylesHtml}
                </head>
                <body class='printer'>
                    <button class='print-button' onClick='window.print();return false;'>
                        Imprimir
                    </button>
                    <table class='events-detail'>
                        ${table}
                    </table>
                </body>
            </html>`);
        printer.document.close();
    }
    onBackButtonPressed() {
        this.$emit('input', false);
    }
};
tslib_1.__decorate([
    Prop({ default: false })
], EventsPrintDialog.prototype, "value", void 0);
tslib_1.__decorate([
    Watch('start'),
    Watch('end')
], EventsPrintDialog.prototype, "onDateChanged", null);
tslib_1.__decorate([
    Watch('value')
], EventsPrintDialog.prototype, "onValueChanged", null);
tslib_1.__decorate([
    Watch('type')
], EventsPrintDialog.prototype, "onTypesChanged", null);
EventsPrintDialog = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, DatePicker
        }
    })
], EventsPrintDialog);
export default EventsPrintDialog;
