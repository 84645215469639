import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundPage from "@/pages/NotFound/NotFoundPage.vue";
import LoginPage from "@/pages/Login/LoginPage.vue";
import DashboardPage from "@/pages/Dashboard/DashboardPage.vue";
import ConfigurationPage from '@/pages/Configuration/ConfigurationPage.vue';
import UsersPage from '@/pages/Users/UsersPage.vue';
import UserPage from '@/pages/Users/UserPage/UserPage.vue';
import ProfilePage from "@/pages/Profile/ProfilePage.vue";
import { User } from '@/models/User';
import EventTypePage from '@/pages/EventTypes/EventTypesPage.vue';
import MaintenancePage from '@/pages/Maintenance/MaintenancePage.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/dashboard',
        name: 'Principal',
        component: DashboardPage
    },
    {
        path: '/maintenance',
        name: 'Servicio de Mantenimiento',
        component: MaintenancePage
    },
    {
        path: '/configuration',
        name: 'Configuración',
        component: ConfigurationPage
    },
    {
        path: '/system/administrators',
        name: 'Administradores',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: UsersPage
    },
    {
        path: '/system/administrators/:id',
        name: 'Datos del administrador',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: UserPage
    },
    {
        path: '/users',
        name: 'Técnicos',
        props: { type: User.TYPE_USER },
        component: UsersPage
    },
    {
        path: '/users/:id',
        name: 'Datos del técnico',
        props: { type: User.TYPE_USER },
        component: UserPage
    },
    {
        path: '/event-types',
        name: 'Tipos de eventos',
        component: EventTypePage
    },
    {
        path: '/profile',
        name: 'Mi perfil',
        component: ProfilePage
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFoundPage
    }
];
const router = new VueRouter({
    routes,
    mode: 'history'
});
export default router;
