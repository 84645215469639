import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialAvatar from "@/components/Base/MaterialAvatar/MaterialAvatar.vue";
import InputField from '@/components/InputField/InputField.vue';
import UserService from '@/services/UserService';
import Sentinel from '@/classes/Sentinel';
import JSPage from '@/classes/JSPage';
let ProfilePage = class ProfilePage extends JSPage {
    constructor() {
        super(...arguments);
        this.userService = new UserService();
    }
    created() {
        super.created();
    }
    main() { }
    update() {
        this.userService.update(this.user).subscribe((user) => {
            Sentinel.setUser(user);
        }, (error) => { });
    }
};
ProfilePage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard,
            MaterialAvatar,
            InputField
        }
    })
], ProfilePage);
export default ProfilePage;
