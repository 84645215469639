import Assets from "@/classes/Assets";
import { EnumEntry } from "./EnumEntry";
export default class EventType {
    constructor(data) {
        this.name = '';
        this.description = '';
        this.show_in_maintenance = 0;
        this.type = new EnumEntry();
        this.icon = '';
        this._notFoundIcon = false;
        this._DEFAULT_ICON = Assets.getImage('default_icon.png');
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
    get showInMaintenance() {
        return this.show_in_maintenance === 1 ? 'Sí' : 'No';
    }
    getIconSrc() {
        return (this.icon && this.icon !== '' && !this._notFoundIcon) ?
            this.icon :
            this.getDefaultIcon();
    }
    getDefaultIcon() {
        return this.icon_FILE;
    }
    catchIconError() {
        this._notFoundIcon = true;
    }
    toNormalize() {
        let reg = Object.assign({}, this);
        delete (reg.id);
        delete (reg._notFoundIcon);
        delete (reg._DEFAULT_ICON);
        if (reg.icon_FILE) {
            reg.icon = reg.icon_FILE;
        }
        delete (reg.icon_FILE);
        return reg;
    }
}
